import React, { Suspense } from 'react'

import Loader from 'react-loader-spinner'
import close from './close.svg'
import styles from './takeover-image.module.css'
import { useImage } from 'react-image'

const LoadingImage = ({ imgSrc, className }) => {
  const { src } = useImage({
    srcList: imgSrc,
  })

  return <img className={className} src={src} />
}

export const TakeOverImage = ({
  item: { src, title },
  onClose,
  onRightClicked,
  onLeftClicked,
}) => (
  <div className={styles.container}>
    <div className={styles.navigationIcons}>
      <button className={styles.leftArrow} onClick={onLeftClicked}/>
      <button className={styles.close} onClick={onClose}>
        <img src={close} />
      </button>
      <button className={styles.rightArrow} onClick={onRightClicked}/>
    </div>
    <div className={styles.imgContainer}>
      <Suspense
        fallback={
          <Loader
            className={styles.loader}
            type="Puff"
            color="rgba(255,255,255,0.5)"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        }
      >
        <LoadingImage className={styles.img} imgSrc={src} />
        <h1 className={styles.title}>{title}</h1>
      </Suspense>
    </div>
  </div>
)
