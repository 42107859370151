import Button from '../components/button'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import React from 'react'
import Scrollbars from 'react-scrollbars-custom'
import { TakeOverImage } from '../components/takeover-image'
import blogPost from './blog-post.module.css'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import heroStyles from '../components/hero.module.css'
import masonryStyles from '../components/masonry.module.css'

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: -1,
    }

    this.toggleTakeover = this.toggleTakeover.bind(this)
    this.onViewImage = this.onViewImage.bind(this)
    this.onHandleKeyPress = this.onHandleKeyPress.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onHandleKeyPress)
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onHandleKeyPress)
  }

  toggleTakeover(idx) {
    this.setState({
      currentIndex: idx,
    })
  }

  onHandleKeyPress(e) {
    console.log(e);
    switch(e.key) {
      case "ArrowLeft":
        this.onViewImage(-1)();
        break;
      case "ArrowRight":
        this.onViewImage(+1)();
        break;
      case "Escape":
        this.toggleTakeover(-1);
        break;
    }
  }

  onViewImage(direction) {
    return () => {
      const exhibitions = get(this.props, 'data.contentfulExhibition')
      let currentIndex = this.state.currentIndex + direction

      if (currentIndex < 0) {
        currentIndex = exhibitions.gallery.length - 1
      }

      if (currentIndex > exhibitions.gallery.length - 1) {
        currentIndex = 0
      }

      this.setState({
        currentIndex,
      })
    }
  }

  render() {
    const exhibition = get(this.props, 'data.contentfulExhibition')
    const exhibitions = get(this, 'props.data.allContentfulExhibition.edges')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    let takeover = null

    if (this.state.currentIndex >= 0) {
      const imageObj = exhibition.gallery[this.state.currentIndex]

      const item = {
        src: imageObj.fixed.src,
        title: imageObj.title,
      }

      takeover = (
        <TakeOverImage
          onLeftClicked={this.onViewImage(-1)}
          onRightClicked={this.onViewImage(+1)}
          item={item}
          onClose={() => this.toggleTakeover(-1)}
        />
      )
    }

    return (
      <Layout location={this.props.location} exhibitions={exhibitions}>
        <Helmet title={`${exhibition.title} | ${siteTitle}`} />
        {takeover}
        <div className={blogPost.wrapper}>
          <div className={blogPost.leftColumn}>
            <h1 className={blogPost.title}>{exhibition.title}</h1>
            {exhibition.description.description.split('\n').map((item) => (
              <p>{item}</p>
            ))}
            <Button to="/contact">Contact</Button>
          </div>
          <div className={blogPost.rightColumn}>
            <div className={masonryStyles.container}>
              {exhibition.gallery &&
                exhibition.gallery.map((item, idx) => (
                  <div
                    onClick={() => this.toggleTakeover(idx)}
                    className={masonryStyles.item}
                  >
                    <Img
                      className={masonryStyles.itemImg}
                      alt={item.title}
                      fluid={item.fluid}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulExhibition {
      edges {
        node {
          title
          slug
        }
      }
    }
    contentfulExhibition(slug: { eq: $slug }) {
      title
      description {
        description
      }
      gallery {
        title
        fixed(width: 1000) {
          src
        }
        fluid(maxWidth: 800, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
        description
      }
    }
  }
`
